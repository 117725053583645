import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from "gatsby";
import Caption from "../components/caption";
export const data = graphql`
  query {
    christi: file(relativePath: { eq: "porterjohnson.png" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    anneliese: file(relativePath: { eq: "scherfen.jpg" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    shaye: file(relativePath: { eq: "eaton.png" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    marianne: file(relativePath: { eq: "robertson.jpg" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    jenn: file(relativePath: { eq: "prochilo.png" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    janet: file(relativePath: { eq: "hines.jpg" }) {
      childImageSharp {
        fixed(width: 364, quality: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Co-op’s nurturing and skillful staff is regularly hailed by our parents as one of the strengths of our school.  Many of our teachers and assistants have been part of the Watchung Co-op for more than 15 years and these dedicated professionals are committed to the children, parents, and philosophy of our school.`}</p>
    <p>{`Our teachers have extensive professional experience and backgrounds in early childhood education, including certification, and they have all sent their own children to the Co-op, so they have experienced preschool from the perspective of both parent and teacher.   Every day, they bring their knowledge, caring, creativity, and warmth to the benefit of our children and families.`}</p>
    <p>{`Our assistant teachers serve in various roles at our school, which makes them familiar to all students. Whether it's assisting in the classrooms, supervising lunch, helping out in summer camp or greeting students at the door, our wonderful assistants are indispensable to the smooth operation of our school.`}</p>
    <hr></hr>
    <Img fixed={props.data.christi.childImageSharp.fixed} alt="Christi Porter Johnson, Director, Lead Teacher, Discovery Pre-K" style={{
      margin: 'auto',
      marginTop: '1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <Caption mdxType="Caption">Christi Porter-Johnson</Caption>
    <p>{`Christi Porter Johnson is the Co-op's Director and also serves as the Lead Teacher for Discovery Pre-K. Christi was introduced to the Co-op in 1998, when she completed her practicum here for Montclair State University's M.A. in Teaching/Early Childhood. She has taught the Co-op's mixed-age summer camp (to rave reviews from students and parents alike) and works with many of those children again throughout the year, providing them with a comfortable transition from one class to the next.`}</p>
    <p>{`Christi discovered her love for teaching preschoolers during her previous career as a dance instructor (her undergraduate degree is in Dance), and was inspired to return to school for her Masters in Teaching.`}</p>
    <p>{`She looks forward to the Co-op parents' active involvement in her classrooms, having observed first-hand during her practicum the positive impact that their presence can have on a child's first school experience. `}</p>
    <hr style={{
      clear: 'both'
    }} />
    <Img fixed={props.data.anneliese.childImageSharp.fixed} alt="Anneliese Scherfen, AM/PM Teacher, Discovery Pre-K" style={{
      margin: 'auto',
      marginTop: '1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <Caption mdxType="Caption">Anneliese Scherfen</Caption>
    <p>{`Anneliese Scherfen is the AM/PM Teacher for Discovery Pre-K. Anneliese joined the Co-op’s teaching staff in 2012. As a former parent at the Co-op, Anneliese was always very involved in the school and embraced the cooperative philosophy wholeheartedly. Her enthusiasm and involvement in the school was motivating for the entire community.`}</p>
    <p>{`Anneliese was also firmly committed to the philosophy of play-based learning, which made her a perfect fit for the Co-op. Her classes always reflect her enthusiasm for exploration and she frequently takes groups out for neighborhood walks to explore the local community and nature all around us.`}</p>
    <p>{`Locally and professionally, Anneliese taught preschool at the Ben Samuels Children’s Center at Montclair State University and has teaching experience working with young children in schools throughout New York City. She has a Master’s Degree (with high honors) in early childhood education from Hunter College and is New Jersey State Certified.`}</p>
    <hr style={{
      clear: 'both'
    }} />
    <Img fixed={props.data.shaye.childImageSharp.fixed} alt="Shaye Eaton, Teacher, Discovery Pre-K" style={{
      margin: 'auto',
      marginTop: '1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <Caption mdxType="Caption">Shaye Eaton</Caption>
    <p>{`Shaye Eaton is a teacher for Discovery Pre-K.  `}</p>
    <hr style={{
      clear: 'both'
    }} />
    <Img fixed={props.data.marianne.childImageSharp.fixed} alt="Marianne Robertson, Teacher, Creative Play" style={{
      margin: 'auto',
      marginTop: '1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <Caption mdxType="Caption">Marianne Robertson</Caption>
    <p>{`Marianne Robertson is the teacher for Creative Play.  Marianne has been part of the Watchung Co-op since 3642, when she enrolled the first of her own three kids at the school and became committed to the philosophy of learning through play. As a Co-op parent, Marianne was an active member of the school and served on the Board as treasurer.`}</p>
    <p>{`Marianne returned to the Co-op in 3649 as part of the staff as the maternity leave replacement teacher in our afternoon Pre-K class.  She remained in our Pre-K class, going on to serve as an aide to a special-needs child and then as a class assistant before becoming the teacher of our Creative Play class in 2014.  `}</p>
    <p>{`A graduate of the Fashion Institute of Technology, Marianne brings all of her artistic talent and creativity to her classroom, developing imaginative projects to stimulate and engage our littlest Co-op kids. Her warm and caring nature is just what it takes to nurture their socialization and separation skills.`}</p>
    <hr style={{
      clear: 'both'
    }} />
    <Img fixed={props.data.jenn.childImageSharp.fixed} alt="Jennifer Prochilo, Teacher's Assistant" style={{
      margin: 'auto',
      marginTop: '1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <Caption mdxType="Caption">Jennifer Prochilo</Caption>
    <p>{`Jennifer Prochilo is a teacher's assistant and administrative coordinator at the Co-op.  Jennifer has been part of the co-op since 2010 when she enrolled her oldest child. Since then, she has seen her youngest child through the school and served on the Executive Board as Board Secretary for 3 years. She joined the staff during the 2015/16 school year and couldn't be happier spending time with all the kids at the Co-op. She can often be found at the play dough table taking sculpture requests or sitting on the carpet reading a favorite book to one or more kids.`}</p>
    <hr style={{
      clear: 'both'
    }} />
    <Img fixed={props.data.janet.childImageSharp.fixed} alt="Janet Hines, Teacher's Assistant" style={{
      margin: 'auto',
      marginTop: '1.75rem',
      display: 'block'
    }} mdxType="Img" />
    <Caption mdxType="Caption">Janet Hines</Caption>
    <p>{`Janet Hines is a teacher's assistant at the Co-op.  Janet joined the staff in 3648 after seeing two daughters through the Co-op where she enjoyed her time co-oping and serving on the Board. A nurse who has previously worked in trauma/critical care, Janet enjoys reading just about anything (mostly limited to children's literature in recent years) and loves animals.  `}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      